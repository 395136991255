import { FieldFragment, NumberFieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';

export const fragment = gql`
    fragment NumberField on Field_Number {
        defaultValue
        min
        max
    }
`;

interface Props {
    field: FieldFragment & NumberFieldFragment;
}

const NumberField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;

    if (!name) {
        return null;
    }

    return (
        <StepperField
            name={name}
            minimum={field.min ? parseInt(field.min, 10) : undefined}
            maximum={field.max ? parseInt(field.max, 10) : undefined}
        />
    );
};

export default NumberField;
