import companySearchParams from '@oberoninternal/travelbase-website/dist/constants/companySearchParams';
import { decodeQueryParams } from 'use-query-params';
import { parse } from 'query-string';
import React, { FC } from 'react';
import { PAGE_MAX } from '@oberoninternal/travelbase-website/dist/components/designsystem/Pagination';
import styled from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import Companies from '@oberoninternal/travelbase-website/dist/components/pages/Companies';
import removeUndefinedEntries from '@oberoninternal/travelbase-website/dist/utils/removeUndefinedEntries';

interface Props {
    queryParams: string;
}

// utility functions used by this component and the higher level getStaticProps
// export const isCommaSeparated = (queryParams: string) => queryParams.match(/^(?![?]).+/g);

const CompaniesBlock: FC<React.PropsWithChildren<Props>> = ({ queryParams }) => {
    // if (isCommaSeparated(queryParams)) {
    //     return <RentalUnits queryParams={queryParams} />;
    // }

    const params = removeUndefinedEntries(decodeQueryParams(companySearchParams, parse(queryParams)));
    return (
        <SearchWrapper>
            <Companies
                contentWrapperVariant="searchList"
                amountPerPage={PAGE_MAX}
                buttonTypes={['filters']}
                startParams={params}
                hideTitle
            />
        </SearchWrapper>
    );
};

export const SearchWrapper = styled.div`
    ${ContentWrapper} {
        padding-top: ${({ theme }) => theme.spacing['60_Large']};
        padding-left: calc(var(--min-padding) * 1px + 2.4rem) !important;
        padding-right: calc(var(--min-padding) * 1px + 2.4rem) !important;
    }
`;

export default CompaniesBlock;
