import React, { FC } from 'react';
import { gql } from '@apollo/client';
import { CardFragment, HierarchicalTilesBlockFragment, TilesBlockFragment } from '../../generated/graphql';
import Card from '../Card';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    data: TilesBlockFragment | HierarchicalTilesBlockFragment;
    pageChildren: Maybe<Array<Maybe<CardFragment>>>;
    pageSiblings: Maybe<Array<Maybe<CardFragment>>>;
}

export const fragment = gql`
    fragment TilesBlock on contentMatrix_sectionTiles_BlockType {
        tilesChildren
        tilesSiblings
        tilesEntries {
            ...Card
        }
    }

    fragment HierarchicalTilesBlock on contentMatrix_sectionHierarchicalTiles_BlockType {
        relationType
    }
`;

const TilesBlock: FC<React.PropsWithChildren<Props>> = ({ data, pageChildren, pageSiblings }) => {
    const entries =
        data.__typename === 'contentMatrix_sectionTiles_BlockType' && data.tilesEntries ? [...data.tilesEntries] : [];
    if (
        (data.__typename === 'contentMatrix_sectionTiles_BlockType' && data.tilesChildren) ||
        (data.__typename === 'contentMatrix_sectionHierarchicalTiles_BlockType' &&
            data.relationType === 'tilesChildren')
    ) {
        entries.push(...(pageChildren?.filter((child): child is NonNullable<typeof child> => !!child) ?? []));
    }
    if (
        (data.__typename === 'contentMatrix_sectionTiles_BlockType' && data.tilesSiblings) ||
        (data.__typename === 'contentMatrix_sectionHierarchicalTiles_BlockType' &&
            data.relationType === 'tilesSiblings')
    ) {
        entries.push(...(pageSiblings?.filter((child): child is NonNullable<typeof child> => !!child) ?? []));
    }

    return (
        <ContentWrapper>
            <Box pt={[5, 7]} pb={7}>
                <TilesWrapper>{entries?.map((entry, key) => entry && <Card key={key} item={entry} />)}</TilesWrapper>
            </Box>
        </ContentWrapper>
    );
};

export default TilesBlock;

const TilesWrapper = styled.div`
    position: relative;

    > * + * {
        margin-bottom: 3.2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        grid-gap: 3.2rem;
    }
`;
