import { PAGE_MAX } from '@oberoninternal/travelbase-website/dist/components/designsystem/Pagination';
import Agenda, { BaseProps } from '@oberoninternal/travelbase-website/dist/components/pages/Agenda';
import { GetStaticProps } from 'next';
import addGlobalQuery from '../../utils/addGlobalQuery';
import React from 'react';
import styled from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import cssCalcBetweenViewport from '../../utils/cssCalcBetweenViewport';
import initApolloClient, { PreviewData } from '../../initApolloClient';
import { AgendaDocument, AgendaFragment, AgendaQuery, AgendaQueryVariables } from '../../generated/graphql';
import { DEFAULT_REVALIDATION_TIME } from '../../constants/revalidationTime';
import { addApolloState } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import { gql } from '@apollo/client';
import Seo from '../../components/Seo';

export const query = gql`
    query Agenda($lang: String) {
        entry(section: ["agenda"], site: [$lang]) {
            ...Agenda
        }
    }

    fragment Agenda on agenda_agenda_Entry {
        title
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
`;

interface Props extends BaseProps {
    entry: AgendaFragment;
}

const Index = (props: Props) => {
    const { title, settingsSeoTitle, settingsMetaDescription, localized } = props.entry;

    return (
        <AgendaWrapper>
            <Seo
                settingsSeoTitle={settingsSeoTitle ?? title ?? ''}
                settingsMetaDescription={settingsMetaDescription ?? ''}
                image={undefined}
                alternates={localized}
            />
            <Agenda {...props} amountPerPage={PAGE_MAX} />
        </AgendaWrapper>
    );
};

export default Index;

export const AgendaWrapper = styled.div`
    padding: 0;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        ${ContentWrapper as never} {
            padding-left: calc(var(--logoOffsetLeft) + ${cssCalcBetweenViewport(20, 40)});
            padding-right: ${cssCalcBetweenViewport(20, 40)}!important;
        }
    }
`;

export const getStaticProps: GetStaticProps = async (context) => {
    if (context.locale === 'default') return { props: {} };

    const { previewData } = context;
    const client = initApolloClient(context.locale as string, { previewData: previewData as PreviewData });

    const { data } = await client.query<AgendaQuery, AgendaQueryVariables>({
        query: AgendaDocument,
        variables: {
            lang: context.locale as string,
        },
    });

    const { entry } = data;

    return {
        ...addApolloState(
            client,
            (await addGlobalQuery(
                {
                    props: {
                        entry,
                    },
                },
                context.locale
            )) as { props: Record<string, unknown> }
        ),
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};
