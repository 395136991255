import React, { FC } from 'react';
import { decodeQueryParams } from 'use-query-params';
import { parse } from 'query-string';
import activitySearchParams from '@oberoninternal/travelbase-website/dist/constants/activitySearchParams';
import { AgendaWrapper } from '../../pages/agenda';
import Agenda from '@oberoninternal/travelbase-website/dist/components/pages/Agenda';
import { PAGE_MAX } from '@oberoninternal/travelbase-website/dist/components/designsystem/Pagination';
import removeUndefinedEntries from '@oberoninternal/travelbase-website/dist/utils/removeUndefinedEntries';

interface Props {
    queryParams: string;
}

const ActivitiesBlock: FC<React.PropsWithChildren<Props>> = ({ queryParams }) => {
    const params = removeUndefinedEntries(decodeQueryParams(activitySearchParams, parse(queryParams)));

    return (
        <AgendaWrapper>
            <Agenda amountPerPage={PAGE_MAX} startParams={params} hideTitle />
        </AgendaWrapper>
    );
};

export default ActivitiesBlock;
