import styled from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import { Box } from '@rebass/grid';
import PlayIcon from '../icons/PlayIcon';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import dynamic from 'next/dynamic';

interface Props {
    videoUrl: string;
    autoplay: boolean;
}

const ReactPlayer = dynamic(() => import('react-player'));

const VideoBlock: FC<React.PropsWithChildren<Props>> = ({ videoUrl, autoplay }) => {
    const [previewing, setPreviewing] = useState(true);
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

    useEffect(() => {
        fetch(`https://noembed.com/embed?url=${videoUrl}`)
            .then(res => res.json())
            .then((resp: { thumbnail_url: string }) => setThumbnailUrl(resp.thumbnail_url));
    }, [videoUrl]);

    return (
        <VideoContentWrapper>
            <Box mb={[3, 5]} mt={[4, 6]}>
                <PlayerContainer>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {previewing && !autoplay ? (
                        <Preview
                            style={{ backgroundImage: `url(${thumbnailUrl})` }}
                            onClick={() => setPreviewing(false)}
                        >
                            <PlayIcon />
                        </Preview>
                    ) : (
                        <ReactPlayer url={videoUrl} playing controls />
                    )}
                </PlayerContainer>
            </Box>
        </VideoContentWrapper>
    );
};

export default VideoBlock;

const VideoContentWrapper = styled(ContentWrapper as FC<React.PropsWithChildren<unknown>>)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding: 0;
    }
`;

const Preview = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PlayerContainer = styled.div`
    max-width: 92.8rem;
    position: relative;
    width: 100% !important;
    height: auto !important;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${Math.round((9 / 16) * 100)}%;
    }

    > * {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: auto !important;
    }
`;
