import React from 'react';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { Box } from '@rebass/grid';
import styled from 'styled-components';
import LinkField from '../LinkField';
import { LinkFieldFragment } from '../../generated/graphql';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    link: LinkFieldFragment;
}

const ButtonBlock = ({ link }: Props) => (
    <ContentWrapper>
        <StyledBox pt={3} pb={3}>
            <Button rounded size="large">
                <LinkField link={link} />
            </Button>
        </StyledBox>
    </ContentWrapper>
);

export default ButtonBlock;

const StyledBox = styled(Box)`
    display: flex;

    a {
        text-decoration: none;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.neutral['0']};
    }
`;
