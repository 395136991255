import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { AddressFieldFragment, FieldFragment } from '../../generated/graphql';
import Fieldset from '@oberoninternal/travelbase-website/dist/components/designsystem/Fieldset';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { globalValidationMessages } from '../Form';
import { useCountryOptions } from '@oberoninternal/travelbase-website/dist/hooks/useDetailsFormOptions';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import styled from 'styled-components';
import Caret from '../icons/Caret';

export const fragment = gql`
    fragment AddressField on Field_Address {
        address1Label
        address1Hidden
        address1Enabled
        address1ErrorMessage
        address1Required
        address1DefaultValue
        address1Placeholder
        zipLabel
        zipHidden
        zipEnabled
        zipRequired
        zipCollapsed
        zipPlaceholder
        zipDefaultValue
        cityLabel
        cityHidden
        cityEnabled
        cityRequired
        cityPlaceholder
        cityDefaultValue
        cityErrorMessage
        countryLabel
        countryHidden
        countryEnabled
        countryRequired
        countryPlaceholder
        countryDefaultValue
        countryDefaultValue
    }
`;

interface Props {
    field: FieldFragment & AddressFieldFragment;
}

export interface Address {
    address1: string;
    city: string;
    zip: string;
    country: string;
}

const StyledCaret = styled(Caret)`
    color: ${({ theme }) => theme.colors.secondary['50']} !important;
    width: 2rem;
    height: 2rem;
`;

const AddressField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();
    const countryOptions = useCountryOptions();

    if (!name) {
        return null;
    }

    return (
        <>
            {field.address1Enabled && (
                <Fieldset label={field.address1Label} id={`${name}.address1`}>
                    <TextInputField
                        placeholder={field.address1Placeholder ?? undefined}
                        name={`${name}.address1`}
                        validate={(value: string) => {
                            if (!value && field.address1Required) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </Fieldset>
            )}
            {field.cityEnabled && (
                <Fieldset label={field.cityLabel} id={`${name}.city`}>
                    <TextInputField
                        placeholder={field.cityPlaceholder ?? undefined}
                        name={`${name}.city`}
                        validate={(value: string) => {
                            if (!value && field.cityRequired) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </Fieldset>
            )}
            <Wrapper>
                {field.zipEnabled && (
                    <Fieldset label={field.zipLabel} id={`${name}.zip`}>
                        <TextInputField
                            placeholder={field.zipPlaceholder ?? undefined}
                            name={`${name}.zip`}
                            validate={(value: string) => {
                                if (!value && field.zipRequired) {
                                    return field.errorMessage
                                        ? field.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </Fieldset>
                )}
                {field.countryEnabled && (
                    <Fieldset label={field.countryLabel} id={`${name}.country`}>
                        <StyledSelectInput
                            name={`${name}.country`}
                            hideChoices
                            options={countryOptions}
                            filterFromStart
                            id="country"
                            isSearchable
                            ToggleComponent={StyledCaret ?? undefined}
                            required={field.countryRequired}
                            placeholder={<FormattedMessage defaultMessage="Kies een optie..." />}
                            validate={(value: string) => {
                                if (!value && field.countryRequired) {
                                    return field.errorMessage
                                        ? field.errorMessage
                                        : formatMessage(globalValidationMessages.required);
                                }
                                return;
                            }}
                        />
                    </Fieldset>
                )}
            </Wrapper>
        </>
    );
};

export default AddressField;

const Wrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;

        > div {
            width: 49%;
        }
    }
`;

const StyledSelectInput = styled(SelectInput)`
    > div {
        min-height: 5.2rem;
    }
`;
