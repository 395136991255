import { FieldFragment, NameFieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { globalValidationMessages } from '../Form';
import { useIntl } from 'react-intl';
import Fieldset from '@oberoninternal/travelbase-website/dist/components/designsystem/Fieldset';
import styled from 'styled-components';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Caret from '../icons/Caret';

export const fragment = gql`
    fragment NameField on Field_Name {
        name
        prefixLabel
        prefixEnabled
        prefixRequired
        firstNameLabel
        firstNameEnabled
        firstNamePlaceholder
        firstNameRequired
        middleNameLabel
        middleNameEnabled
        middleNamePlaceholder
        middleNameRequired
        lastNameLabel
        lastNameEnabled
        lastNamePlaceholder
        lastNameRequired
    }
`;

interface Props {
    field: FieldFragment & NameFieldFragment;
}

const StyledCaret = styled(Caret)`
    color: ${({ theme }) => theme.colors.secondary['50']} !important;
    width: 2rem;
    height: 2rem;
`;

export interface Name {
    firstName: string;
    lastName: string;
    middleName?: string;
    prefix?: string;
}

const NameField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();
    if (!name) {
        return null;
    }

    const prefixOptions = [
        {
            label: formatMessage({ defaultMessage: 'Dhr.' }),
            value: 'mr',
        },
        {
            label: formatMessage({ defaultMessage: 'Mevr.' }),
            value: 'mrs',
        },
    ];

    return (
        <FieldsWrapper>
            {field.prefixEnabled && (
                <FieldsetSmall label={field.prefixLabel} id={`${name}.prefix`}>
                    <StyledSelectInput
                        name={`${name}.prefix`}
                        hideChoices
                        options={prefixOptions}
                        filterFromStart
                        id="prefix"
                        ToggleComponent={StyledCaret ?? undefined}
                        required={field.prefixRequired}
                        placeholder={''}
                        validate={(value: string) => {
                            if (!value && field.prefixRequired) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </FieldsetSmall>
            )}
            {field.firstNameEnabled && (
                <FieldsetBig label={field.firstNameLabel} id={`${name}.firstName`}>
                    <TextInputField
                        placeholder={field.firstNamePlaceholder ?? undefined}
                        name={`${name}.firstName`}
                        validate={(value: string) => {
                            if (!value && field.firstNameRequired) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </FieldsetBig>
            )}
            {field.middleNameEnabled && (
                <FieldsetSmall label={field.middleNameLabel} id={`${name}.middleName`}>
                    <TextInputField
                        placeholder={field.middleNamePlaceholder ?? undefined}
                        name={`${name}.middleName`}
                        validate={(value: string) => {
                            if (!value && field.middleNameRequired) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </FieldsetSmall>
            )}
            {field.lastNameEnabled && (
                <FieldsetBig label={field.lastNameLabel} id={`${name}.lastName`}>
                    <TextInputField
                        placeholder={field.lastNamePlaceholder ?? undefined}
                        name={`${name}.lastName`}
                        validate={(value: string) => {
                            if (!value && field.lastNameRequired) {
                                return field.errorMessage
                                    ? field.errorMessage
                                    : formatMessage(globalValidationMessages.required);
                            }
                            return;
                        }}
                    />
                </FieldsetBig>
            )}
        </FieldsWrapper>
    );
};

export default NameField;

const FieldsWrapper = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;

        > div {
            margin-right: 1%;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
`;

const FieldsetSmall = styled(Fieldset)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 15%;
    }
`;

const FieldsetBig = styled(Fieldset)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-grow: 2;
    }
`;
const StyledSelectInput = styled(SelectInput)`
    > div {
        min-height: 5.2rem;
    }
`;
