import { CheckboxesFieldFragment, FieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { Box } from '@rebass/grid';
import { useIntl } from 'react-intl';
import { globalValidationMessages } from '../Form';
import { useField, useFormikContext } from 'formik';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';

export const fragment = gql`
    fragment CheckboxesField on Field_Checkboxes {
        options {
            isDefault
            value
        }
    }
`;

interface Props {
    field: FieldFragment & CheckboxesFieldFragment;
}

const someFieldIsTrue = (obj: Record<string, boolean | undefined>, name: Maybe<string>) =>
    Object.keys(obj).some(key => key.startsWith(`${name}_`) && !!obj[key]);

const CheckboxesField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();
    const { values, touched, getFieldHelpers, getFieldProps, validateField } =
        useFormikContext<Record<string, boolean>>();

    const [, { error }] = useField({
        name: name ?? '',
        validate: () => {
            const valid = someFieldIsTrue(values, name);
            if (!valid && field.required) {
                return field.errorMessage ? field.errorMessage : formatMessage(globalValidationMessages.required);
            }
            return;
        },
    });

    const checkboxesTouched = useMemo(() => someFieldIsTrue(touched, name), [name, touched]);

    if (!name) {
        return null;
    }

    return (
        <>
            {field.options?.map((option, i) => {
                if (!option) {
                    return;
                }
                const fieldName = `${name}_${option.value}`;
                const fieldHelpers = getFieldHelpers(fieldName);
                const fieldProps = getFieldProps(fieldName);
                return (
                    option && (
                        <Box mb={[2, 4]} key={option.value ?? i}>
                            <Checkbox
                                value={fieldProps.value}
                                id={option.value ?? undefined}
                                onChange={e => {
                                    fieldHelpers.setValue(e.currentTarget.checked, false);
                                    fieldHelpers.setTouched(true);
                                    // this hack ensures it also gets validated after the checkbox changed
                                    setTimeout(() => validateField(name));
                                }}
                            >
                                {option.value}
                            </Checkbox>
                        </Box>
                    )
                );
            })}

            {checkboxesTouched && error && <ErrorMessage>{error}</ErrorMessage>}
        </>
    );
};

export default CheckboxesField;
