import { gql } from '@apollo/client';
import React, { FC, useMemo } from 'react';
import {
    BannerFragment,
    CardFragment,
    ContentMatrix_SectionAccordion_BlockType,
    ContentMatrixFragment,
    FormFragment,
    PhotosCarouselGalleryFragment,
    PinsFragment,
    TipFragment,
} from '../generated/graphql';
import HtmlMapper from 'react-html-map';
import tagMap from '../constants/tagMap';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import AccordionBlock from './blocks/AccordionBlock';
import ButtonBlock from './blocks/ButtonBlock';
import PhotosCarousel from './PhotosCarousel';
import WebcamBlock from './blocks/WebcamBlock';
import VideoBlock from './blocks/VideoBlock';
import MapBlock from './blocks/MapBlock';
import AccommodationsBlock from './blocks/AccommodationsBlock';
import TipBlock from './blocks/TipBlock';
import TilesBlock from './blocks/TilesBlock';
import FormBlock from './blocks/FormBlock';
import BannerBlock from './blocks/BannerBlock';
import EmbedBlock from './blocks/EmbedBlock';
import styled from 'styled-components';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import CompaniesBlock from './blocks/CompaniesBlock';
import { FAQPage, Question, WithContext } from 'schema-dts';
import striptags from 'striptags';
import SmallContentWrapper from './SmallContentWrapper';
import ActivitiesBlock from './blocks/ActivitiesBlock';
import TravelbaseTilesBlock from './blocks/TravelbaseTilesBlock';
import Head from 'next/head';

export const fragment = gql`
    fragment ContentMatrix on contentMatrix_MatrixField {
        ... on contentMatrix_sectionText_BlockType {
            text
        }
        ... on contentMatrix_sectionHeader_BlockType {
            header
        }
        ... on contentMatrix_sectionVideo_BlockType {
            videoUrl
            autoplay
        }
        ... on contentMatrix_sectionImages_BlockType {
            gallery: images {
                ...PhotosCarouselGallery
            }
        }
        ... on contentMatrix_sectionAccordion_BlockType {
            header
            text
        }
        ... on contentMatrix_sectionButton_BlockType {
            button {
                ...LinkField
            }
        }
        ... on contentMatrix_sectionWebcam_BlockType {
            webcamUrl
        }
        ... on contentMatrix_sectionMap_BlockType {
            pins {
                ...Pins
            }
        }
        ... on contentMatrix_sectionAccommodations_BlockType {
            accommodationsQuery
        }
        ... on contentMatrix_sectionCompanies_BlockType {
            companiesQuery
        }
        ... on contentMatrix_sectionActivities_BlockType {
            activitiesQuery
        }
        ... on contentMatrix_sectionTip_BlockType {
            tip {
                ...Tip
            }
        }
        ... on contentMatrix_sectionBanner_BlockType {
            banner {
                ...Banner
            }
        }
        ... on contentMatrix_sectionTiles_BlockType {
            ...TilesBlock
        }

        ... on contentMatrix_sectionHierarchicalTiles_BlockType {
            ...HierarchicalTilesBlock
        }
        ... on contentMatrix_sectionEmbed_BlockType {
            embed
        }
        ... on contentMatrix_sectionForms_BlockType {
            form {
                ...Form
            }
        }
        ... on contentMatrix_sectionTravelbaseTiles_BlockType {
            ...TravelbaseTile
        }
    }
    fragment TravelbaseTile on contentMatrix_sectionTravelbaseTiles_BlockType {
        travelbaseSlugs {
            slug
        }
        travelbaseEntityType
    }
`;

interface Props {
    matrix: ContentMatrixFragment[];
    pageChildren: Maybe<Array<Maybe<CardFragment>>>;
    pageSiblings: Maybe<Array<Maybe<CardFragment>>>;
}

const GROUPED_BLOCKS: ContentMatrixFragment['__typename'][] = [
    'contentMatrix_sectionText_BlockType',
    'contentMatrix_sectionHeader_BlockType',
    'contentMatrix_sectionAccordion_BlockType',
];

const ContentMatrix: FC<React.PropsWithChildren<Props>> = ({ matrix, pageChildren, pageSiblings }) => {
    const accordionItems = matrix.filter(
        (item): item is ContentMatrix_SectionAccordion_BlockType =>
            item.__typename === 'contentMatrix_sectionAccordion_BlockType'
    );

    const questions: Question[] = accordionItems.map(item => ({
        '@type': 'Question',
        name: item.header ?? '',
        acceptedAnswer: {
            '@type': 'Answer',
            text: striptags(item.text ?? ''),
        },
    }));

    const schema: WithContext<FAQPage> = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions,
    };

    const groupedMatrixItems = useMemo(() => {
        const toReturn = matrix.reduce<ContentMatrixFragment[][]>(
            (prev, cur) => {
                if (GROUPED_BLOCKS.find(b => b === cur.__typename)) {
                    return [...prev.slice(0, -1), [...prev[prev.length - 1], cur]];
                }
                return [...prev, [cur], []];
            },
            [[]]
        );

        return toReturn[toReturn.length - 1].length === 0 ? toReturn.slice(0, -1) : toReturn;
    }, [matrix]);

    return (
        <Container>
            {accordionItems.length > 0 && (
                <Head>
                    {/* eslint-disable-next-line react/no-danger */}
                    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
                </Head>
            )}
            {groupedMatrixItems.map((group, gi) =>
                group?.[0]?.__typename && GROUPED_BLOCKS.find(b => b === group[0].__typename) ? (
                    <SmallContentWrapper key={gi}>
                        {group.map((item, i) => (
                            <ContentMatrixItem
                                pageChildren={pageChildren}
                                pageSiblings={pageSiblings}
                                item={item}
                                key={i}
                            />
                        ))}
                    </SmallContentWrapper>
                ) : (
                    group.map((item, i) => (
                        <ContentMatrixItem
                            pageChildren={pageChildren}
                            pageSiblings={pageSiblings}
                            item={item}
                            key={i}
                        />
                    ))
                )
            )}
        </Container>
    );
};

const Container = styled.div``;

const ContentMatrixItem: FC<
    React.PropsWithChildren<{ item: ContentMatrixFragment } & Pick<Props, 'pageChildren' | 'pageSiblings'>>
> = ({ item, pageChildren, pageSiblings }) => {
    switch (item?.__typename) {
        case 'contentMatrix_sectionText_BlockType':
            return <HtmlMapper html={item.text ?? ''}>{tagMap}</HtmlMapper>;
        case 'contentMatrix_sectionHeader_BlockType':
            return <Title variant="regular">{item.header}</Title>;
        case 'contentMatrix_sectionVideo_BlockType':
            return <VideoBlock videoUrl={item.videoUrl ?? ''} autoplay={item.autoplay ?? false} />;
        case 'contentMatrix_sectionImages_BlockType':
            return (item.gallery?.length ?? 0) > 0 ? (
                <PhotosCarousel gallery={(item.gallery as PhotosCarouselGalleryFragment[]) ?? []} />
            ) : null;
        case 'contentMatrix_sectionAccordion_BlockType':
            return <AccordionBlock title={item.header ?? ''} text={item.text ?? ''} />;
        case 'contentMatrix_sectionButton_BlockType':
            return <ButtonBlock link={item.button ?? {}} />;
        case 'contentMatrix_sectionWebcam_BlockType':
            return <WebcamBlock webcamUrl={item.webcamUrl ?? ''} />;
        case 'contentMatrix_sectionMap_BlockType':
            return <MapBlock pins={(item.pins as PinsFragment[]) ?? []} />;
        case 'contentMatrix_sectionAccommodations_BlockType':
            return item.accommodationsQuery ? <AccommodationsBlock queryParams={item.accommodationsQuery} /> : null;
        case 'contentMatrix_sectionCompanies_BlockType':
            return item.companiesQuery ? <CompaniesBlock queryParams={item.companiesQuery} /> : null;
        case 'contentMatrix_sectionActivities_BlockType':
            return item.activitiesQuery ? <ActivitiesBlock queryParams={item.activitiesQuery} /> : null;
        case 'contentMatrix_sectionTip_BlockType':
            return item.tip?.[0] ? <TipBlock tip={item.tip?.[0] as TipFragment} /> : null;
        case 'contentMatrix_sectionTravelbaseTiles_BlockType':
            return <TravelbaseTilesBlock data={item} />;
        case 'contentMatrix_sectionBanner_BlockType':
            return item.banner?.[0] ? <BannerBlock banner={item.banner[0] as BannerFragment} /> : null;
        case 'contentMatrix_sectionTiles_BlockType':
        case 'contentMatrix_sectionHierarchicalTiles_BlockType':
            return <TilesBlock data={item} pageChildren={pageChildren} pageSiblings={pageSiblings} />;
        case 'contentMatrix_sectionEmbed_BlockType':
            return item.embed ? <EmbedBlock embed={item.embed} /> : null;
        case 'contentMatrix_sectionForms_BlockType':
            return item.form ? <FormBlock forms={item.form as FormFragment[]} /> : null;
        default:
            // eslint-disable-next-line no-console
            console.warn(`ContentMatrixItem: unknown type '${item?.__typename}'`);
            return null;
    }
};

export default ContentMatrix;
