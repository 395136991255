import React, { FC } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import HtmlMapper from 'react-html-map';
import tagMap from '../../constants/tagMap';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import SmallContentWrapper from '../SmallContentWrapper';

interface Props {
    title: string;
    text: string;
}

const AccordionBlock: FC<React.PropsWithChildren<Props>> = ({ title, text }: Props) => (
    <Wrapper className="accordion">
        <SmallContentWrapper>
            <StyledAccordion allowZeroExpanded>
                <StyledAccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <TitleWrapper py={[2, 3]} my={[2, 3]}>
                                <StyledTitle elementType={'h3'} variant={'small'}>
                                    {title}
                                </StyledTitle>
                            </TitleWrapper>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Box pb={5} className="accordion-content">
                            <HtmlMapper html={text}>{tagMap}</HtmlMapper>
                        </Box>
                    </AccordionItemPanel>
                </StyledAccordionItem>
            </StyledAccordion>
        </SmallContentWrapper>
    </Wrapper>
);

export default AccordionBlock;

const StyledAccordion = styled(Accordion)`
    .accordion__panel {
        animation: fadein 0.35s ease-in;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

const StyledAccordionItem = styled(AccordionItem)`
    .accordion__button {
        outline: none !important;
        cursor: pointer;
        border-top: 0.1rem solid ${({ theme }) => theme.colors.neutral['20']};
        transition: color 0.3s ease-in-out;
        *:hover {
            color: ${({ theme }) => theme.colors.primary['60']};
            h3 {
                color: ${({ theme }) => theme.colors.primary['60']};
            }
        }
    }

    .accordion__button div:after {
        display: inline-block;
        content: '';
        height: 0.8rem;
        width: 0.8rem;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(45deg);
        margin-right: 0.4rem;
        margin-top: 0.2rem;
        position: relative;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            width: 1rem;
            height: 1rem;
        }
    }

    .accordion__button[aria-expanded='true'] div:after,
    .accordion__button[aria-selected='true'] div:after {
        transform: rotate(-135deg);
        top: 5px;
    }

    .accordion-content > :first-child {
        margin-top: 0;
    }
`;

const TitleWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

const StyledTitle = styled(Title)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        font-size: 1.5rem;
    }
    margin: 0 !important;
`;

const Wrapper = styled.div`
    margin-top: 4rem;
    > div {
        padding: 0;
    }
    & + & {
        margin-top: 0;
        margin-bottom: 0;
    }
`;
