import React, { FC } from 'react';
import { gql } from '@apollo/client';
import { PinsFragment } from '../../generated/graphql';
import styled from 'styled-components';
import DefaultMarker from '../icons/markers/DefaultMarker';
import HouseMarker from '../icons/markers/HouseMarker';
import TreeMarker from '../icons/markers/TreeMarker';
import { Box } from '@rebass/grid';
import { useIntl } from 'react-intl';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import dynamic from 'next/dynamic';

const StaticMap = dynamic(() => import('@oberoninternal/travelbase-ds/components/misc/StaticMap'), { ssr: false });

export const fragment = gql`
    fragment Pins on pins_TableRow {
        latitude
        longitude
        symbol
        label
    }
`;

interface Props {
    pins: PinsFragment[] | undefined | null;
}

const GetMarker = (type: string) => {
    switch (type) {
        case 'house':
            return <HouseMarker />;
        case 'tree':
            return <TreeMarker />;
        default:
            return <DefaultMarker />;
    }
};

const MapBlock: FC<React.PropsWithChildren<Props>> = ({ pins }) => {
    const { formatMessage } = useIntl();

    return (
        <ContentWrapper>
            <Box mt={6} mb={6}>
                <MapWrapper>
                    {pins && (
                        <StaticMap
                            mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY}
                            showMapText={formatMessage({
                                id: 'showMap',
                                defaultMessage: 'Kaart tonen',
                            })}
                            zoom={8.9}
                            minZoom={5}
                            fullWidth
                            markers={pins.map(pin => ({
                                markerPosition: { longitude: pin.longitude, latitude: pin.latitude },
                                markerElement: GetMarker(pin.symbol ?? ''),
                            }))}
                        />
                    )}
                </MapWrapper>
            </Box>
        </ContentWrapper>
    );
};

export default MapBlock;

const MapWrapper = styled.div`
    height: 40rem;
    width: 100%;
    color: ${({ theme }) => theme.colors.neutral['0']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        height: 50rem;
    }

    svg {
        color: ${({ theme }) => theme.colors.primary['80']};
    }
`;
