import React, { SVGAttributes } from 'react';

const TreeMarker = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width={72} height={72} {...props}>
        <defs />
        <defs>
            <filter
                id="TreeMarker-a"
                width="183.3%"
                height="183.3%"
                x="-41.7%"
                y="-33.3%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.229949 0 0 0 0 0.461254 0 0 0 0 0.626472 0 0 0 0.32 0"
                />
            </filter>
            <circle id="TreeMarker-b" cx="24" cy="24" r="24" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(12 8)">
                <use fill="#000" filter="url(#TreeMarker-a)" xlinkHref="#TreeMarker-b" />
                <use fill="#FFF" xlinkHref="#TreeMarker-b" />
            </g>
            <path
                fill="#000"
                fillRule="nonzero"
                d="M36.964 38.029a5.993 5.993 0 01-.737-.28.536.536 0 00-.446 0c-.239.109-.485.202-.737.28a.536.536 0 00-.379.512v4.834a1.34 1.34 0 102.679 0v-4.834a.536.536 0 00-.38-.512zM43.374 26.139a1.071 1.071 0 01-.451-.721 3.193 3.193 0 00-2.269-2.54 1.071 1.071 0 01-.743-.783 4.018 4.018 0 00-7.822 0 1.071 1.071 0 01-.743.784 3.193 3.193 0 00-2.268 2.539 1.071 1.071 0 01-.452.72 4.022 4.022 0 00.088 6.673c.229.148.392.378.456.643a4.345 4.345 0 006.31 2.689c.323-.18.717-.18 1.04 0a4.346 4.346 0 006.314-2.69c.063-.264.226-.494.455-.642a4.022 4.022 0 00.085-6.672z"
            />
        </g>
    </svg>
);

export default TreeMarker;
