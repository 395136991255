import React, { FC } from 'react';
import { DropdownFieldFragment, FieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import Caret from '../icons/Caret';
import styled from 'styled-components';
import { globalValidationMessages } from '../Form';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';

export const fragment = gql`
    fragment DropdownField on Field_Dropdown {
        options {
            label
            isDefault
            value
        }
    }
`;

interface Props {
    field: FieldFragment & DropdownFieldFragment;
}

const StyledCaret = styled(Caret)`
    color: ${({ theme }) => theme.colors.secondary['50']} !important;
    width: 2rem;
    height: 2rem;
`;

const DropdownField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();

    const options = field?.options?.map(option => ({ value: option?.value ?? '', label: option?.label ?? '' }));

    if (!name || !options) {
        return null;
    }

    return (
        <SelectInput
            name={name}
            required={field.required}
            validate={(value: string) => {
                if (!value && field.required) {
                    return field.errorMessage ? field.errorMessage : formatMessage(globalValidationMessages.required);
                }
                return;
            }}
            options={options}
            hideChoices
            ToggleComponent={StyledCaret ?? undefined}
            placeholder={<FormattedMessage defaultMessage="Kies een optie..." />}
        />
    );
};

export default DropdownField;
