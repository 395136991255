import { gql } from '@apollo/client';
import { PhotosCarouselGalleryFragment } from '../generated/graphql';
import React, { FC, useState } from 'react';
import { Box } from '@rebass/grid';
import styled, { css } from 'styled-components';
import Img from '@oberoninternal/travelbase-website/dist/components/Img';
import Carousel from '@oberoninternal/travelbase-website/dist/components/designsystem/Carousel';
import PhotoModal from '@oberoninternal/travelbase-website/dist/components/designsystem/PhotoModal';
import { FormattedMessage } from 'react-intl';
import { ImageCategories } from '@oberoninternal/travelbase-website/dist/utils/groupImagesByCategory';
import { tileTransformToProps } from '../constants/imageTransforms';
import { useRouter } from 'next/router';
import SmallContentWrapper from './SmallContentWrapper';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { ItemList, ListItem, WithContext } from 'schema-dts';
import getCurrentBaseUrl from '../utils/getCurrentBaseUrl';
import Head from 'next/head';

export const fragment = gql`
    fragment PhotosCarouselGallery on AssetInterface {
        ... on library_Asset {
            assetSource
        }
        ...GalleryTransform
    }
`;

interface Props {
    gallery: PhotosCarouselGalleryFragment[];
}

const getAspectRatio = (img: PhotosCarouselGalleryFragment) => {
    if (!img.width || !img.height) {
        return 9 / 16;
    }

    const ratio = img.width / img.height;

    // If the ratio value is smaller than one, the image should be a portrait
    if (ratio < 1) {
        return 3 / 2;
    }

    return 9 / 16;
};

const PhotosCarousel: FC<React.PropsWithChildren<Props>> = ({ gallery }) => {
    const [open, setOpen] = useState(false);
    const [startIndex, setStartIndex] = useState<number | undefined>();
    const { asPath, locale } = useRouter();

    const listItemsSchema: ListItem[] = gallery.map((image, i) => ({
        '@type': 'ListItem',
        position: i,
        name: image.title ?? '',
        url: getCurrentBaseUrl(locale) + asPath,
        image: image.url ?? '',
    }));

    const schema: WithContext<ItemList> = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: listItemsSchema,
    };
    return (
        <>
            <Head>
                <script
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
                />
            </Head>

            {gallery.length === 1 && (
                <SmallContentWrapper>
                    <Box mt={[4, 6]} mb={[4, 6, 8]}>
                        <SinglePhotoContainer
                            imgRatio={getAspectRatio(gallery[0])}
                            onClick={() => {
                                setStartIndex(0);
                                setOpen(true);
                            }}
                        >
                            <Img
                                {...tileTransformToProps(gallery[0])}
                                layout="ratio"
                                ratio={getAspectRatio(gallery[0])}
                            />
                            <AssetSource as="footer">
                                <SEOInfoTitle>{gallery[0].title}</SEOInfoTitle>
                                {gallery[0].assetSource && (
                                    <SEOInfo>
                                        <small>
                                            <FormattedMessage defaultMessage="Fotograaf:" /> {gallery[0].assetSource}
                                        </small>
                                    </SEOInfo>
                                )}
                            </AssetSource>
                        </SinglePhotoContainer>
                    </Box>
                </SmallContentWrapper>
            )}

            {gallery.length > 1 && (
                <Carousel continuous>
                    {gallery.map(
                        (image, i) =>
                            image && (
                                <StyledBox
                                    key={i}
                                    pl={[3, 6]}
                                    onClick={() => {
                                        setStartIndex(i);
                                        setOpen(true);
                                    }}
                                >
                                    <StyledPhotoWrapper>
                                        <StyledPhoto {...tileTransformToProps(image)} />
                                        <AssetSource as="footer">
                                            <SEOInfoTitle>{image.title}</SEOInfoTitle>
                                            {image.__typename === 'library_Asset' && image.assetSource && (
                                                <SEOInfo>
                                                    <small>
                                                        <FormattedMessage defaultMessage="Fotograaf:" />{' '}
                                                        {image.assetSource}
                                                    </small>
                                                </SEOInfo>
                                            )}
                                        </AssetSource>
                                    </StyledPhotoWrapper>
                                </StyledBox>
                            )
                    )}
                </Carousel>
            )}
            <PhotoModal
                hideCount
                startIndex={startIndex}
                open={open}
                onClose={() => setOpen(false)}
                imagesOrCategories={gallery
                    .map((image): ImageCategories[0] | null => {
                        const { srcset, srcsetWebp, url, width, height, placeholderUrl: placeholder } = image;
                        const ratio = (width ?? 1) / (height ?? 1);

                        return url
                            ? {
                                  translatedCategoryName: image.assetSource ? (
                                      <AssetSource>
                                          {image.title}
                                          {image.__typename === 'library_Asset' && image.assetSource && (
                                              <SEOInfo as="footer">
                                                  <small>
                                                      <FormattedMessage defaultMessage="Fotograaf:" />{' '}
                                                      {image.assetSource}
                                                  </small>
                                              </SEOInfo>
                                          )}
                                      </AssetSource>
                                  ) : (
                                      ''
                                  ),
                                  images: [
                                      {
                                          webp: {
                                              src: url ?? '',
                                              srcSet: srcsetWebp ?? undefined,
                                              placeholder,
                                              ratio,
                                          },
                                          jpeg: {
                                              src: url ?? '',
                                              srcSet: srcset ?? undefined,
                                              placeholder,
                                              ratio,
                                          },
                                      },
                                  ],
                              }
                            : null;
                    })
                    .filter((cat): cat is NonNullable<typeof cat> => !!cat)}
            />
        </>
    );
};
export default PhotosCarousel;

const gradientBackground = css`
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) -1%, rgba(0, 0, 0, 0) 72%, rgba(0, 0, 0, 0.16) 100%);

        mix-blend-mode: multiply;
        z-index: -1;
    }
`;

const AssetSource = styled(Body).attrs({ size: 'tiny' })`
    font-weight: 600;
    bottom: 0;
    z-index: 2;
    padding-left: ${({ theme }) => theme.spacing['50_Semi']};
    font-size: 1.2rem;
    line-height: 1.5;
    margin-right: ${({ theme }) => theme.spacing['40_Standard']};
    position: relative;

    &::before {
        content: '';
        width: ${({ theme }) => theme.spacing['40_Standard']};
        height: ${({ theme }) => theme.spacing['10_Micro']};
        background-color: ${({ theme }) => theme.colors.secondary[30]};
        position: absolute;
        border-radius: 1.6rem;
        left: 0;
        margin: 0.6rem 0 0 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        font-size: inherit;
        padding-left: ${({ theme }) => theme.spacing['60_Large']};

        &::before {
            width: ${({ theme }) => theme.spacing['50_Semi']};
            height: ${({ theme }) => theme.spacing['10_Micro']};
            margin: 1rem 0 0 0;
        }
    }
`;
const AssetSourcePos = css`
    position: absolute;
    width: 100%;
    padding: 50% 2.4rem 1.6rem 4rem;
    &::before {
        margin-left: 1.6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        padding: 50% 2.4rem 2.4rem 5.6rem;
        &::before {
            margin-left: 2.4rem;
        }
    }
    ${gradientBackground};
`;

const StyledPhotoWrapper = styled.div`
    position: relative;
    color: ${({ theme }) => theme.colors.neutral[0]};
    ${AssetSource} {
        ${AssetSourcePos};
    }
`;

const StyledBox = styled(Box)`
    scroll-snap-align: start;
    position: relative;

    &:last-child {
        padding-right: 3.2rem;
    }
`;

const SEOInfoTitle = styled.figcaption`
    font-weight: 600;
`;

const SEOInfo = styled.figcaption`
    font-size: 1.4rem;
    line-height: 1.25;
    color: inherit;
    font-weight: 500;
`;

const SinglePhotoContainer = styled.div<{ imgRatio: number }>`
    cursor: pointer;
    max-width: 928px;
    margin-bottom: ${({ theme }) => theme.spacing['80_XXLarge']};
    position: relative;
    color: ${({ theme }) => theme.colors.neutral[0]};
    ${({ imgRatio }) =>
        // only if the image is portrait we want the whole thing in view
        imgRatio > 1 &&
        css`
            max-width: 500px;
            img {
                object-fit: contain;
                object-position: left;
            }
        `};

    ${AssetSource} {
        ${AssetSourcePos};
    }
`;

const StyledPhoto = styled(Img)`
    margin: 0;
    width: 32rem;
    height: 24rem;
    position: relative;
    cursor: pointer;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 44.8rem;
        height: 33.6rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        width: 92.8rem;
        height: 69.6rem;
    }
`;
