import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
    webcamUrl: string;
}

const HOTEL_NOORDZEE_WEBCAM_HANDLE = 'strandhotelTX';
const HOTEL_NOORDZEE_CONTROL_WEBCAM_URL = 'https://www.webcams-texel.nl/strandhotelTXptz';

const WebcamBlock: FC<React.PropsWithChildren<Props>> = ({ webcamUrl }) => {
    const { locale } = useRouter();

    /**
     * Noordzee has a controllable camera
     * Show controls if current webcamUrl is from Hotel Noordzee
     */
    const isHotelNoordzeeCamera = webcamUrl.includes(HOTEL_NOORDZEE_WEBCAM_HANDLE);
    let cameraControlUrl = HOTEL_NOORDZEE_CONTROL_WEBCAM_URL;

    if (isHotelNoordzeeCamera) {
        cameraControlUrl = getHotelNoordZeeCameraControlUrl(locale ?? 'nl');
    }

    return (
        <>
            <WebcamContentWrapper>
                <Webcam>
                    <StyledIframe src={webcamUrl} frameBorder="0" allowFullScreen />
                </Webcam>
                {isHotelNoordzeeCamera && (
                    <ControlledWebcamBase>
                        <ControlledWebcamIframe src={cameraControlUrl} />
                    </ControlledWebcamBase>
                )}
            </WebcamContentWrapper>
        </>
    );
};

const getHotelNoordZeeCameraControlUrl = (locale: string) => {
    switch (locale) {
        case 'de':
            return HOTEL_NOORDZEE_CONTROL_WEBCAM_URL.concat('DE');
        case 'en':
            return HOTEL_NOORDZEE_CONTROL_WEBCAM_URL.concat('UK');
        default:
            return HOTEL_NOORDZEE_CONTROL_WEBCAM_URL;
    }
};

export default WebcamBlock;

const Webcam = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
`;

const WebcamContentWrapper = styled(ContentWrapper as FC<React.PropsWithChildren<unknown>>)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding: 0;
    }
`;

const StyledIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ControlledWebcamBase = styled.div`
    top: 0;
    left: 0;
    width: 100%;

    @media screen and (min-width: 575px) {
        height: 250px;
    }
`;

const ControlledWebcamIframe = styled.iframe`
    width: 100%;
    height: 540px;
    border: none;
    padding: 0 var(--wrapperPadding);

    @media screen and (min-width: 575px) {
        height: 250px;
        padding: 0;
    }
`;
