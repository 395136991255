import React, { CSSProperties, FC, useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { DropzoneOptions, DropzoneProps, DropzoneState, FileError, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Theme } from '@oberoninternal/travelbase-ds/constants/theme';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { Box } from '@rebass/grid';
import { defineMessages, useIntl } from 'react-intl';
import { globalValidationMessages } from './Form';
import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';

/**
 * Largely taken from: https://github.com/KaiHotz/react-formik-ui/blob/master/src/components/DropZone/DropZone.tsx
 */

type DropZoneOptionsState = DropzoneOptions & DropzoneState;

const messages = defineMessages({
    placeholder: {
        defaultMessage: 'Sleep een bestand hier naar toe, of klik om een bestand te selecteren.',
    },
    placeholderMobile: {
        defaultMessage: 'Klik om een bestand te selecteren.',
    },
    zoneActiveText: {
        defaultMessage: 'Sleep bestand(en) hier naar toe.',
    },
    filesRejected: {
        defaultMessage: 'Er zijn enkele bestanden afgewezen.',
    },
});

const rejectionMessages = defineMessages<FileError['code']>({
    'file-too-large': {
        defaultMessage: 'Bestand is groter dan {maxSizeMb} mb.',
    },
    'file-too-small': {
        defaultMessage: 'Bestand is kleiner dan {minSizeMb} mb.',
    },
    'too-many-files': {
        defaultMessage: 'Teveel bestanden, maximale hoeveelheid is {maxFiles}.',
    },
    'file-invalid-type': {
        defaultMessage: 'Ongeldig bestandstype, probeer opnieuw.',
    },
});

export const MB_IN_B = 1024 * 1024;

export interface Props extends Partial<DropZoneOptionsState> {
    /** Sets the Name of the DropZone Field */
    name: string;
    /** Adds a custom class to the React-Dropzone component */
    className?: string;
    /** Adds a custom inline styles to the DropZone wrapper div  */
    style?: CSSProperties;
    /** Sets an Id for the Dropzone, if not passed, the id will be the name */
    id?: string;
    /** Set accepted file types. See [https://github.com/okonet/attr-accept](https://github.com/okonet/attr-accept) for more information. Keep in mind that mime type determination is not reliable across platforms. CSV files, for example, are reported as text/plain under macOS but as application/vnd.ms-excel under Windows. In some cases there might not be a mime type set at all. See: [https://github.com/react-dropzone/react-dropzone/issues/276](https://github.com/react-dropzone/react-dropzone/issues/276) */
    accept?: string;
    /** Sets the main Label for the DropZone Field */
    label?: string;
    /** Sets the text to be shown when draging files over the drop zone */
    zoneActiveText?: string;
    /** Sets the Placeholder text */
    placeholder?: string;

    placeholderMobile?: string;
    /** Allow drag 'n' drop (or selection from the file dialog) of multiple files. Set to false to enable Single file upload */
    multiple?: boolean;

    required?: boolean;
}

interface FileWithPreview extends File {
    preview?: string;
}

const DropZone: FC<React.PropsWithChildren<Props>> = ({
    disabled,
    id,
    name,
    accept,
    className,
    multiple = true,
    required = false,
    ...rest
}) => {
    const { formatMessage } = useIntl();
    const {
        zoneActiveText = formatMessage(messages.zoneActiveText),
        placeholder = formatMessage(messages.placeholder),
        placeholderMobile = formatMessage(messages.placeholderMobile),
    } = rest;
    const validate = useCallback(
        (value: File[]) => {
            if (required && value.length === 0) {
                return formatMessage(globalValidationMessages.required);
            }

            if (rest.maxFiles && value.length > rest.maxFiles) {
                return formatMessage(rejectionMessages['too-many-files'], {
                    maxFiles: rest.maxFiles,
                });
            }

            return;
        },
        [formatMessage, required, rest.maxFiles]
    );
    const [rejections, setRejections] = useState<string[]>([]);

    const { setValues } = useFormikContext();
    const [{ value }, { error, touched }, { setValue, setTouched }] = useField<FileWithPreview[]>({
        name,
        validate,
    });

    const onDrop: DropzoneProps['onDrop'] = (droppedFiles, fileRejections) => {
        if (fileRejections.length) {
            setRejections(
                fileRejections.map(
                    rejection =>
                        `${rejection.file.name}: ${rejection.errors
                            .map(err =>
                                formatMessage(rejectionMessages[err.code], {
                                    minSizeMb: Math.round((rest.minSize ?? 0) / MB_IN_B),
                                    maxSizeMb: Math.round((rest.maxSize ?? 0) / MB_IN_B),
                                    maxFiles: rest.maxFiles,
                                })
                            )
                            .join(', ')}`
                )
            );
        }

        for (const file of droppedFiles) {
            if (file.type.includes('image')) {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            }
        }

        setValues((values: { [k in string]: FileWithPreview[] }) => {
            const prevFiles = values[name];
            const files: FileWithPreview[] = [...prevFiles, ...droppedFiles];
            return {
                ...values,
                [name]: files,
            };
        });
        setTouched(true);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
        accept,
        onDrop,
        disabled,
        multiple,
        ...rest,
    });

    return (
        <>
            <section>
                <Container
                    {...getRootProps()}
                    isDragAccept={isDragAccept}
                    isDragReject={isDragReject}
                    isDragActive={isDragActive}
                >
                    <input {...getInputProps()} id={id ?? name} name={name} />
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {acceptedFiles.length && value.length ? (
                        value.map((file, i) => (
                            <File
                                key={i}
                                style={file.preview ? { backgroundImage: `url("${file.preview}")` } : {}}
                                onClick={e => e.preventDefault()}
                            >
                                <RemoveButton
                                    variant={file.preview ? 'outline-inverted' : 'outline'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        const newVal = [...value];
                                        newVal.splice(i, 1);
                                        setValue(newVal);

                                        if (file.preview) {
                                            URL.revokeObjectURL(file.preview);
                                        }
                                    }}
                                >
                                    <Cross />
                                </RemoveButton>
                                {!file.preview && file.name}
                            </File>
                        ))
                    ) : isDragActive ? (
                        <Body>{zoneActiveText}</Body>
                    ) : (
                        <Body>
                            <span className="gt-m">{placeholder}</span>
                            <span className="lt-m">{placeholderMobile}</span>
                        </Body>
                    )}
                </Container>

                {error && touched && (
                    <Box mt={2}>
                        <ErrorMessage>{error}</ErrorMessage>
                    </Box>
                )}

                {rejections.length > 0 && (
                    <Box mt={4}>
                        <Toast title={formatMessage(messages.filesRejected)} variant="warn">
                            {rejections.map((rejection, i) => (
                                <Body variant="tiny" key={i}>
                                    {rejection}
                                </Body>
                            ))}
                        </Toast>
                    </Box>
                )}
            </section>
        </>
    );
};

type DropzoneContainerProps = Pick<DropzoneState, 'isDragAccept' | 'isDragReject' | 'isDragActive'>;
const getColor = (props: DropzoneContainerProps, theme: Theme) => {
    if (props.isDragAccept) {
        return theme.colors.positive['40'];
    }
    if (props.isDragReject) {
        return theme.colors.negative['40'];
    }
    if (props.isDragActive) {
        return theme.colors.primary['40'];
    }
    return '#eeeeee';
};

const Container = styled.div<DropzoneContainerProps>`
    flex: 1;
    display: flex;
    padding: 1.2rem;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-radius: 0.5rem;
    color: ${({ theme }) => theme.colors.neutral['30']};
    border: ${({ theme, ...props }) => `2px dashed ${getColor(props, theme)}`};
    position: relative;
    min-height: 12rem;
    flex-wrap: wrap;

    p {
        align-self: center;
        width: 100%;
        text-align: center;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: 14rem;
    }
`;

const File = styled.div`
    position: relative;
    width: 18.2rem;
    height: 16.8rem;
    border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
    background-size: cover;
    margin: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.8rem;
    word-break: break-all;
    text-align: center;
`;

const RemoveButton = styled(RoundButton)`
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
`;

export default DropZone;
