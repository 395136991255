import React, { FC } from 'react';
import styled from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

const SmallContentWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <ContentWrapper>
        <SmallContent>{children}</SmallContent>
    </ContentWrapper>
);

export default SmallContentWrapper;

const SmallContent = styled.div`
    max-width: 92.8rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: ${({ theme }) => theme.spacing['10_Micro']};
        margin-top: ${({ theme }) => theme.spacing['60_Large']};
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            margin-top: ${({ theme }) => theme.spacing['70_XLarge']};
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
            margin-top: ${({ theme }) => theme.spacing['80_XXLarge']};
        }
    }
    h2,
    h2 > strong {
        font-weight: 800;

        @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            font-size: 1.8rem;
        }
    }
    p {
        margin-bottom: ${({ theme }) => theme.spacing['30_Small']};
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
        }
    }
`;
