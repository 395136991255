import React, { FC } from 'react';
import { useRentalUnitsQuery } from '../generated/graphql';
import UnitTile, { Byline, Text } from '@oberoninternal/travelbase-ds/components/misc/UnitTile';
import { gql } from '@apollo/client';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Link from './Link';
import createPlaceholder from '@oberoninternal/travelbase-website/dist/utils/createPlaceholder';
import Rating from '@oberoninternal/travelbase-website/dist/components/Rating';
import getRentalUnitSummaryText from '@oberoninternal/travelbase-website/dist/utils/getRentalUnitSummaryText';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { useTenantContext } from '@oberoninternal/travelbase-website/dist/context/TenantContext';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    queryParams: string;
}

export const fragment = gql`
    query RentalUnits($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...rentalUnit
        }
    }

    fragment rentalUnit on RentalUnit {
        id
        name
        slug
        type
        amountBedrooms
        maxOccupancy
        tagline
        accommodation {
            name
            hasPublicPage
            place
        }
        reviewStats {
            average
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }

    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
    }
`;

export const getSlugs = (queryParams: string) => queryParams.split(',').map(slug => slug.trim());

const RentalUnits: FC<React.PropsWithChildren<Props>> = ({ queryParams }) => {
    const slugs = getSlugs(queryParams);
    const { brandConfig } = useTenantContext();
    const { data } = useRentalUnitsQuery({
        ssr: false,
        variables: {
            slugs,
        },
    });
    const intl = useIntl();

    if (!data) {
        return null;
    }

    return (
        <ContentWrapper>
            <Wrapper>
                {data.rentalUnits.map((rentalUnit, key) => {
                    const subTitleText = getRentalUnitSummaryText(intl, brandConfig)(
                        rentalUnit.accommodation.place,
                        rentalUnit.type,
                        rentalUnit.maxOccupancy,
                        rentalUnit.amountBedrooms
                    );
                    const average = rentalUnit.reviewStats?.average;

                    return (
                        <Link href={`/accomodatie/${rentalUnit.slug}`} key={key}>
                            <StyledLink>
                                <StyledUnitTile
                                    photo={{
                                        loading: 'lazy',
                                        sources: [
                                            {
                                                srcSet: rentalUnit.webpListImage?.transform?.srcSet,
                                                type: 'image/webp',
                                            },
                                            {
                                                srcSet: rentalUnit?.listImage?.transform?.srcSet,
                                                type: 'image/jpeg',
                                            },
                                        ],
                                        fallback: {
                                            src: rentalUnit?.listImage.transform?.src,
                                        },
                                        placeholder: createPlaceholder(rentalUnit.webpListImage.transform?.placeholder),
                                    }}
                                >
                                    <Flex my={2}>{average && <Rating value={average} />}</Flex>
                                    <Byline>
                                        {rentalUnit.accommodation.hasPublicPage
                                            ? `${rentalUnit.accommodation.name}—${rentalUnit.name}`
                                            : `${rentalUnit.name}`}
                                    </Byline>
                                    <Title variant="small">{rentalUnit.tagline}</Title>
                                    <Text variant="small">{subTitleText}</Text>
                                </StyledUnitTile>
                            </StyledLink>
                        </Link>
                    );
                })}
            </Wrapper>
        </ContentWrapper>
    );
};

export default RentalUnits;

const StyledLink = styled.a`
    text-decoration: none;
`;

const Wrapper = styled.section`
    margin: 3.2rem 0;
    display: grid;
    row-gap: 4rem;
    column-gap: 3.2rem;
    position: relative;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 6.4rem;
    }
`;

const StyledUnitTile = styled(UnitTile)`
    cursor: pointer;
    width: auto;

    &:last-of-type {
        margin-right: 0;
    }
`;
