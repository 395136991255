import { FieldFragment, RadioFieldFragment } from '../../generated/graphql';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Box } from '@rebass/grid';
import { useIntl } from 'react-intl';
import { globalValidationMessages } from '../Form';
import { RadioField as RadioFieldDS } from '@oberoninternal/travelbase-ds/components/form/Radio';

export const fragment = gql`
    fragment RadioField on Field_Radio {
        options {
            isDefault
            value
        }
    }
`;

interface Props {
    field: FieldFragment & RadioFieldFragment;
}

const RadioField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();

    if (!name) {
        return null;
    }

    return (
        <>
            {field.options?.map(
                (option, i) =>
                    option && (
                        <Box mb={[2, 4]} key={option.value ?? i}>
                            <RadioFieldDS
                                name={name}
                                validate={(value: boolean) => {
                                    if (field.required && !value) {
                                        return field.errorMessage
                                            ? field.errorMessage
                                            : formatMessage(globalValidationMessages.required);
                                    }

                                    return;
                                }}
                                id={option.value ?? undefined}
                            >
                                {option.value}
                            </RadioFieldDS>
                        </Box>
                    )
            )}
        </>
    );
};

export default RadioField;
