import React, { FC } from 'react';
import { gql } from '@apollo/client';
import { BannerFragment } from '../../generated/graphql';
import Button, { ButtonVariant } from '@oberoninternal/travelbase-ds/components/action/Button';
import LinkField from '../LinkField';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    banner: BannerFragment;
}

export const fragment = gql`
    fragment Banner on banners_banner_Entry {
        title
        heroImage {
            thumbUrl: url @transform(width: 328, height: 340, immediately: true)
        }
        button {
            ...LinkField
        }
    }
`;

const BannerBlock: FC<React.PropsWithChildren<Props>> = ({ banner }) => {
    const { title, heroImage, button } = banner;
    let heroImageUrl = '/static/img/placeholder-banner.png';
    let buttonVariant: ButtonVariant = 'primary';
    if (heroImage?.[0]?.thumbUrl) {
        heroImageUrl = heroImage[0].thumbUrl;
        buttonVariant = 'secondary';
    }

    return (
        <ContentWrapper>
            <Box paddingY={5}>
                <Banner heroImageUrl={heroImageUrl}>
                    <StyledBox paddingX={7} paddingY={5}>
                        <StyledTitle>{title}</StyledTitle>
                        {button && (
                            <StyledButton variant={buttonVariant} size="large">
                                <LinkField link={button}>{button?.customText}</LinkField>
                            </StyledButton>
                        )}
                    </StyledBox>
                </Banner>
            </Box>
        </ContentWrapper>
    );
};

export default BannerBlock;

const Banner = styled.div<{ heroImageUrl?: string | null }>`
    background-image: ${({ heroImageUrl }) =>
        heroImageUrl ? `url('${heroImageUrl}')` : `/static/img/placeholder-banner.png`};
    background-size: cover;
    color: ${({ theme }) => theme.colors.neutral['0']};
    width: 100%;
    height: 34rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 32.8rem;
    }
`;

const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.neutral['0']};
`;

const StyledButton = styled(Button)`
    a {
        text-decoration: none;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.neutral['0']};
    }
`;

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
`;
