import { gql } from '@apollo/client';
import { FieldFragment, FieldRendererFragment, GroupFieldFragment } from '../../generated/graphql';
import React, { FC } from 'react';
import { Box } from '@rebass/grid';
import FieldRenderer from '../FieldRenderer';

export const fragment = gql`
    fragment GroupField on Field_Group {
        fields {
            ...FieldRenderer
        }
    }
`;

interface Props {
    field: FieldFragment & GroupFieldFragment;
}

const GroupField: FC<React.PropsWithChildren<Props>> = ({ field }) => (
    <Box ml={[3, 5]}>
        <FieldRenderer
            fields={
                (field.fields as FieldRendererFragment[]).map(childField => ({
                    ...childField,
                    handle: `${field.handle}.${childField.handle}`,
                })) ?? []
            }
        />
    </Box>
);
export default GroupField;
