import React, { SVGAttributes } from 'react';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';

const PlayIcon = (props: SVGAttributes<SVGElement>) => {
    const theme = useThemeContext();
    return (
        <svg width="91" height="91" viewBox="0 0 91 91" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="translate(.76 .16)" fill="none" fillRule="evenodd">
                <circle fillOpacity=".8" fill={theme.colors.primary[80]} cx="44.64" cy="44.64" r="44.64" />
                <path
                    d="M56.426 45.713L38.62 56.023a2.598 2.598 0 01-3.9-2.25V35.507a2.598 2.598 0 013.9-2.248l17.806 10.309a1.24 1.24 0 010 2.146z"
                    fill={theme.colors.neutral[0]}
                />
            </g>
        </svg>
    );
};

export default PlayIcon;
