import { Address } from '../components/fields/AddressField';
import { Name } from '../components/fields/NameField';
import { FormFragment, Maybe } from '../generated/graphql';
import format from 'date-fns/format';
import { Birthdate } from '@oberoninternal/travelbase-website/dist/entities/Birthdate';
import parseToDateString from '@oberoninternal/travelbase-website/dist/constants/parseToDateString';
import subscribeToNewsletter from './subscribeToNewsletter';

type FieldValue = string | number | boolean | Birthdate | File[] | Address | Name;

export interface CsrfResponse {
    param: string;
    token: string;
}

type Values = Record<string, FieldValue | Record<string, FieldValue>>;
export const toFormData = (
    values: Values,
    fields: FormFragment['fields'],
    recaptchaToken?: string,
    formHandle?: Maybe<string> | undefined,
    siteId?: Maybe<number> | undefined,
    csrf?: CsrfResponse
) => {
    const formData = new FormData();
    if (recaptchaToken) {
        formData.append('g-recaptcha-response', recaptchaToken);
    }
    if (formHandle) {
        formData.append('handle', formHandle);
    }
    if (siteId) {
        formData.append('siteId', siteId.toString());
    }
    if (csrf) {
        formData.append(csrf.param, csrf.token);
    }

    fields?.forEach((field, index) => {
        const key = field?.handle;
        const value: Values[keyof Values] | undefined = Object.entries(values).find(
            ([valueKey]) => key === valueKey
        )?.[1];
        if (!key) {
            return;
        }

        switch (field?.__typename) {
            case 'Field_Checkboxes': {
                formData.append(`fields[${key}]`, '');

                const checkboxValues = field.options?.map((option) => option?.value);
                for (const childValue of checkboxValues ?? []) {
                    if (values[`${key}_${childValue}`] && childValue) {
                        formData.append(`fields[${key}][]`, childValue);
                    }
                }
                break;
            }
            case 'Field_Date': {
                if (field.displayType === 'dropdowns') {
                    const date = value as Birthdate;
                    formData.append(`fields[${key}][Y]`, date.year);
                    formData.append(`fields[${key}][m]`, date.month);
                    formData.append(`fields[${key}][d]`, date.day);
                } else if (value) {
                    formData.append(`fields[${key}][date]`, value.toString());
                }
                break;
            }
            case 'Field_Name': {
                const nameValues = value as Name;
                formData.append(`fields[${key}][firstName]`, nameValues.firstName);
                formData.append(`fields[${key}][lastName]`, nameValues.lastName);
                if (nameValues.middleName) formData.append(`fields[${key}][middleName]`, nameValues.middleName);
                if (nameValues.prefix) formData.append(`fields[${key}][prefix]`, nameValues.prefix);

                break;
            }
            case 'Field_Address': {
                const addressValues = value as Address;
                formData.append(`fields[${key}][address1]`, addressValues.address1);
                formData.append(`fields[${key}][zip]`, addressValues.zip);
                formData.append(`fields[${key}][city]`, addressValues.city);
                formData.append(`fields[${key}][country]`, addressValues.country);

                break;
            }
            case 'Field_FileUpload': {
                const files = value as File[];

                for (const file of files) {
                    formData.append(`fields[${key}][]`, file);
                }
                break;
            }
            case 'Field_Group': {
                const groupValues = value as Values;
                const sortOrder = `${index}`;

                const nested = toFormData(groupValues, field.fields);
                for (const [childKey, childValue] of [...nested.entries()]) {
                    formData.append(
                        childKey.replace(/^fields/g, `fields[${key}][rows][${sortOrder}][fields]`),
                        childValue
                    );
                }

                formData.append(`fields[${key}][sortOrder][]`, sortOrder);
                break;
            }
            case 'Field_Heading':
                // do nothing
                break;
            case 'Field_Agree':
            case 'Field_Newsletter':
                formData.append(`fields[${key}]`, value ? '1' : '');
                break;
            default:
                if (value) {
                    formData.append(`fields[${key}]`, value.toString());
                }
                break;
        }
    });
    return formData;
};
export const buildInitialValues = (fields: FormFragment['fields']): Values =>
    fields?.reduce<Values>((acc, field) => {
        const handle = field?.handle;

        if (!handle) {
            return acc;
        }

        const record: Values = {};
        switch (field?.__typename) {
            case 'Field_SingleLineText':
            case 'Field_MultiLineText':
            case 'Field_Email':
            case 'Field_Phone':
                record[handle] = field.defaultValue ?? '';
                break;
            case 'Field_Number':
                record[handle] = field.defaultValue ? parseInt(field.defaultValue, 10) : 0;
                break;
            case 'Field_Dropdown':
            case 'Field_Radio':
                record[handle] = field.options?.find((option) => option?.isDefault)?.value ?? '';
                break;
            case 'Field_Checkboxes':
                for (const option of field.options ?? []) {
                    record[`${handle}_${option?.value}`] = !!option?.isDefault;
                }
                break;
            case 'Field_FileUpload':
                record[handle] = [];
                break;
            case 'Field_Agree':
            case 'Field_Newsletter':
                record[handle] = !!field.defaultState;
                break;
            case 'Field_Date': {
                let defaultDate = new Date();
                if (field.defaultOption !== 'today' && typeof field.defaultDate === 'string') {
                    defaultDate = new Date(field.defaultDate);
                }

                if (field.displayType === 'dropdowns') {
                    if (field.defaultOption) {
                        record[handle] = {
                            day: format(defaultDate, 'dd'),
                            month: format(defaultDate, 'MM'),
                            year: format(defaultDate, 'yyyy'),
                        };
                    } else {
                        record[handle] = {
                            day: '',
                            month: '',
                            year: '',
                        };
                    }
                } else if (field.defaultOption) {
                    record[handle] = parseToDateString(defaultDate);
                } else {
                    record[handle] = '';
                }
                break;
            }
            case 'Field_Address': {
                const defaultAddress = field.address1DefaultValue;
                const defaultCity = field.cityDefaultValue;
                const defaultZipcode = field.zipDefaultValue;
                const defaultCountry = field.countryDefaultValue;

                record[handle] = {
                    address1: defaultAddress ?? '',
                    city: defaultCity ?? '',
                    zip: defaultZipcode ?? '',
                    country: defaultCountry ?? '',
                };

                break;
            }
            case 'Field_Heading':
                // do nothing
                break;
            case 'Field_Group':
                record[handle] = buildInitialValues(field.fields) as Record<string, FieldValue>;
                break;
            default:
                record[handle] = '';
                break;
        }
        return { ...acc, ...record };
    }, {}) ?? {};

export const subscribeByForm = (values: Values, fields: FormFragment['fields'], locale: string) => {
    const emailHandle = fields?.find((field) => field?.__typename === 'Field_Email')?.handle ?? '';
    const newsletterHandle = fields?.find((field) => field?.__typename === 'Field_Newsletter')?.handle ?? '';
    if (values[newsletterHandle] && values[emailHandle]) {
        subscribeToNewsletter(values[emailHandle] as string, locale);
    }
};
