import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@rebass/grid';
import styled from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

interface Props {
    embed: string;
}

const EmbedBlock: FC<React.PropsWithChildren<Props>> = ({ embed }) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [height, setHeight] = useState<number | null>(null);

    const updateHeight = useCallback(() => {
        const iframe = iframeRef.current;

        setHeight(iframe?.contentDocument?.body?.clientHeight ?? null);
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
        iframe?.addEventListener('load', updateHeight);
        window.addEventListener('resize', updateHeight);

        return () => {
            iframe?.removeEventListener('load', updateHeight);
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    return (
        <ContentWrapper>
            <Box mt={5} mb={3}>
                <Frame ref={iframeRef} src={embed} frameBorder={0} width="100%" height={height ?? 0} />
            </Box>
        </ContentWrapper>
    );
};

const Frame = styled.iframe`
    min-height: 19rem;
    max-width: 100%;
`;

export default EmbedBlock;
