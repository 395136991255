import React, { FC } from 'react';
import {
    TravelbaseTileFragment,
    useActivitiesBySlugQuery,
    useCompaniesBySlugQuery,
    useRentalUnitsBySlugQuery,
    useAccommodationsBySlugQuery,
} from '../../generated/graphql';
import { CardItem } from '../Card';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import { gql } from '@apollo/client';
import useGetPageSlugByLocale from '@oberoninternal/travelbase-website/dist/hooks/useGetPageSlugByLocale';

export const fragment = gql`
    query RentalUnitsBySlug($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...RentalUnitsBySlug
        }
    }

    query CompaniesBySlug($slugs: [String!]!) {
        companiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }

    query ActivitiesBySlug($slugs: [String!]!) {
        activitiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }

    query AccommodationsBySlug($slugs: [String!]!) {
        accommodationsBySlug(slugs: $slugs) {
            ...AccommodationsBySlug
        }
    }

    fragment RentalUnitsBySlug on RentalUnit {
        id
        name
        slug
        tagline
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    fragment AccommodationsBySlug on Accommodation {
        id
        name
        slug
        descriptionGeneral
        webpListImage: mainImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        mainImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
`;

interface Props {
    data: TravelbaseTileFragment;
}

const TravelbaseTilesBlock: FC<React.PropsWithChildren<Props>> = ({
    data: { travelbaseSlugs, travelbaseEntityType },
}) => (
    <ContentWrapper>
        <Box pt={[5, 7]} pb={7}>
            {travelbaseEntityType === 'rentalUnits' && <RentalUnitTile data={{ travelbaseSlugs }} />}
            {travelbaseEntityType === 'accommodations' && <AccommodationTile data={{ travelbaseSlugs }} />}
            {travelbaseEntityType === 'activities' && <ActivityTile data={{ travelbaseSlugs }} />}
            {travelbaseEntityType === 'companies' && <CompanyTile data={{ travelbaseSlugs }} />}
        </Box>
    </ContentWrapper>
);
const ActivityTile: FC<
    React.PropsWithChildren<{
        data?: TravelbaseTileFragment;
    }>
> = ({ data }) => {
    const slugs = data?.travelbaseSlugs?.map(slugEntity => slugEntity?.slug as string) ?? [];
    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const activitySlug = getPageSlugByLocale('ACTIVITY');
    const { data: activityData } = useActivitiesBySlugQuery({
        variables: {
            slugs,
        },
    });

    return (
        <TilesWrapper>
            {[...(activityData?.activitiesBySlug ?? [])]
                ?.sort((activityA, activityB) => slugs.indexOf(activityA.slug) - slugs.indexOf(activityB.slug))
                .map(entry => (
                    <CardItem
                        key={entry.id}
                        uri={`${activitySlug}/${entry.slug}`}
                        title={entry.name}
                        metaDescription={entry.description}
                        img={entry.listImage?.transform}
                        webpListImage={entry?.webpListImage?.transform}
                    />
                ))}
        </TilesWrapper>
    );
};

const CompanyTile: FC<
    React.PropsWithChildren<{
        data?: TravelbaseTileFragment;
    }>
> = ({ data }) => {
    const slugs = data?.travelbaseSlugs?.map(slugEntity => slugEntity?.slug as string) ?? [];
    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const companyPageSlug = getPageSlugByLocale('COMPANY');
    const { data: companyData } = useCompaniesBySlugQuery({
        variables: {
            slugs,
        },
    });

    return (
        <TilesWrapper>
            {[...(companyData?.companiesBySlug ?? [])]
                ?.sort((companyA, companyB) => slugs.indexOf(companyA.slug) - slugs.indexOf(companyB.slug))
                ?.map(entry => (
                    <CardItem
                        key={entry.id}
                        uri={`${companyPageSlug}/${entry.slug}`}
                        title={entry.name}
                        metaDescription={entry.description}
                        img={entry?.listImage?.transform}
                        webpListImage={entry?.webpListImage?.transform}
                    />
                ))}
        </TilesWrapper>
    );
};

const RentalUnitTile: FC<
    React.PropsWithChildren<{
        data?: TravelbaseTileFragment;
    }>
> = ({ data }) => {
    const slugs = data?.travelbaseSlugs?.map(slugEntity => slugEntity?.slug as string) ?? [];
    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const accommodationSlug = getPageSlugByLocale('ACCOMMODATION');
    const { data: rentalUnitData } = useRentalUnitsBySlugQuery({
        variables: {
            slugs,
        },
    });

    return (
        <TilesWrapper>
            {[...(rentalUnitData?.rentalUnits ?? [])]
                ?.sort((rentalUnitA, rentalUnitB) => slugs.indexOf(rentalUnitA.slug) - slugs.indexOf(rentalUnitB.slug))
                ?.map(entry => (
                    <CardItem
                        key={entry.id}
                        uri={`${accommodationSlug}/${entry.slug}`}
                        title={entry.name}
                        metaDescription={entry.tagline}
                        img={entry.listImage.transform}
                        webpListImage={entry.webpListImage.transform}
                    />
                ))}
        </TilesWrapper>
    );
};

const AccommodationTile: FC<
    React.PropsWithChildren<{
        data?: TravelbaseTileFragment;
    }>
> = ({ data }) => {
    const slugs = data?.travelbaseSlugs?.map(slugEntity => slugEntity?.slug as string) ?? [];

    const { data: accommodationData } = useAccommodationsBySlugQuery({
        variables: {
            slugs,
        },
    });

    return (
        <TilesWrapper>
            {[...(accommodationData?.accommodationsBySlug ?? [])]
                ?.sort(
                    (accommodationA, accommodationB) =>
                        slugs.indexOf(accommodationA.slug) - slugs.indexOf(accommodationB.slug)
                )
                ?.map(entry => (
                    <CardItem
                        key={entry.id}
                        uri={`/complex/${entry.slug}`}
                        title={entry.name}
                        metaDescription={entry.descriptionGeneral}
                        img={entry?.mainImage?.transform}
                        webpListImage={entry?.webpListImage?.transform}
                    />
                ))}
        </TilesWrapper>
    );
};

export default TravelbaseTilesBlock;

const TilesWrapper = styled.div`
    position: relative;

    > * + * {
        margin-bottom: 3.2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        grid-gap: 3.2rem;
    }
`;
