import React, { SVGAttributes } from 'react';

const DefaultMarker = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} viewBox="0 0 72 72" {...props}>
        <defs />
        <defs>
            <filter
                id="defaultMarker-a"
                width="183.3%"
                height="183.3%"
                x="-41.7%"
                y="-33.3%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.229949 0 0 0 0 0.461254 0 0 0 0 0.626472 0 0 0 0.32 0"
                />
            </filter>
            <circle id="defaultMarker-b" cx="24" cy="24" r="24" />
            <path id="defaultMarker-c" d="M0 0h12v17.62H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(12 8)">
                <use fill="#000" filter="url(#defaultMarker-a)" xlinkHref="#defaultMarker-b" />
                <use fill="#FFF" xlinkHref="#defaultMarker-b" />
            </g>
            <g transform="translate(30 22)">
                <mask id="defaultMarker-d" fill="#fff">
                    <use xlinkHref="#defaultMarker-c" />
                </mask>
                <path
                    fill="#000"
                    d="M3.75 6A2.25 2.25 0 118.252 6 2.25 2.25 0 013.75 6M12 6A6 6 0 000 6c0 3.72 4.101 9.49 5.523 11.379.24.32.714.32.954 0C7.899 15.49 12 9.719 12 5.999"
                    mask="url(#defaultMarker-d)"
                />
            </g>
        </g>
    </svg>
);

export default DefaultMarker;
