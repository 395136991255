import React, { SVGAttributes } from 'react';

const HouseMarker = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} viewBox="0 0 72 72" {...props}>
        <defs />
        <defs>
            <filter
                id="houseMarker-a"
                width="183.3%"
                height="183.3%"
                x="-41.7%"
                y="-33.3%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.229949 0 0 0 0 0.461254 0 0 0 0 0.626472 0 0 0 0.32 0"
                />
            </filter>
            <circle id="houseMarker-b" cx="24" cy="24" r="24" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(12 8)">
                <use fill="#000" filter="url(#houseMarker-a)" xlinkHref="#houseMarker-b" />
                <use fill="#FFF" xlinkHref="#houseMarker-b" />
            </g>
            <path
                fill="#000"
                d="M37.928 34.793V39.6a.4.4 0 00.398.401H44.6a.4.4 0 00.4-.401V28.887a.402.402 0 00-.16-.32l-8.6-6.487a.4.4 0 00-.48 0l-8.6 6.487a.402.402 0 00-.16.32V39.6a.4.4 0 00.399.401h6.274a.4.4 0 00.399-.401v-4.806c0-1.07.863-1.938 1.927-1.94 1.065 0 1.93.869 1.93 1.94"
            />
        </g>
    </svg>
);

export default HouseMarker;
