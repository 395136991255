import { gql } from '@apollo/client';
import { FieldFragment, FileFieldFragment } from '../../generated/graphql';
import React, { FC } from 'react';
import DropZone, { MB_IN_B } from '../DropZone';

export const fragment = gql`
    fragment FileField on Field_FileUpload {
        restrictFiles
        allowedKinds
        limitFiles
        limitAmount
        sizeLimit
        sizeMinLimit
    }
`;

interface Props {
    field: FieldFragment & FileFieldFragment;
}

const FileField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;

    if (!name) {
        return null;
    }

    let accept: string | undefined;

    if (field.restrictFiles === 'true') {
        accept =
            field.allowedKinds
                ?.map(kind => {
                    switch (kind) {
                        case 'image':
                            return 'image/*';
                        case 'pdf':
                            return '.pdf';
                        case 'audio':
                            return 'audio/*';
                        case 'excel':
                            return '.xlsx, .xls, .csv';
                        case 'flash':
                            return '.swf';
                        case 'compressed':
                            return '.zip';
                        case 'html':
                            return '.html';
                        case 'json':
                            return '.json';
                        case 'javascript':
                            return '.js';
                        case 'powerpoint':
                            return '.ppt, .pptx';
                        case 'text':
                            return '.txt';
                        case 'video':
                            return 'video/*';
                        default:
                            return '';
                    }
                })
                .join(', ') ?? '';
    }

    return (
        <DropZone
            name={name}
            accept={accept}
            maxFiles={field.limitFiles ? parseInt(field.limitFiles, 10) : undefined}
            maxSize={field.sizeLimit ? parseInt(field.sizeLimit, 10) * MB_IN_B : undefined}
            minSize={field.sizeMinLimit ? parseInt(field.sizeMinLimit, 10) * MB_IN_B : undefined}
            required={!!field.required}
        />
    );
};

export default FileField;
