import { gql } from '@apollo/client';
import { AgreeFieldFragment, FieldFragment, NewsletterAgreeFieldFragment } from '../../generated/graphql';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { globalValidationMessages } from '../Form';
import { Box } from '@rebass/grid';
import HtmlMapper from 'react-html-map';
import tagMap from '../../constants/tagMap';

export const fragment = gql`
    fragment AgreeField on FieldInterface {
        ...BaseAgreeField
        ...NewsletterAgreeField
    }

    fragment BaseAgreeField on Field_Agree {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }

    fragment NewsletterAgreeField on Field_Newsletter {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }
`;

interface Props {
    field: FieldFragment & (AgreeFieldFragment | NewsletterAgreeFieldFragment);
}

const AgreeField: FC<React.PropsWithChildren<Props>> = ({ field }) => {
    const name = field.handle;
    const { formatMessage } = useIntl();

    if (!name) {
        return null;
    }

    if (field.__typename !== 'Field_Newsletter' && field.__typename !== 'Field_Agree') {
        return null;
    }

    return (
        <Box mb={[2, 4]}>
            <CheckboxField
                name={name}
                id={name ?? undefined}
                validate={(value: boolean) => {
                    if (field.required && !value) {
                        return field.errorMessage
                            ? field.errorMessage
                            : formatMessage(globalValidationMessages.required);
                    }

                    return;
                }}
            >
                <HtmlMapper html={field.descriptionHtml ?? ''}>{tagMap}</HtmlMapper>
            </CheckboxField>
        </Box>
    );
};
export default AgreeField;
